export const ENDPOINT = "https://s.avos.ac"
export const SERVER_URL = 'https://s.avos.ac/'
export const HALLWAY_API_TOKEN = "00eeab9d-266e-43e8-8720-89b3d96cf4ac"
export const HOST = "https://avos.ac"
export const COMETCHAT_APP_ID = "189769a9d2070a6a"
export const COMETCHAT_AUTH_KEY="b808781d04e87acfb08e06cf5d3f899246599644"
export const EXPERIMENTAL_FEATURE=false
export const IS_TEST = false
export const MASTER_SCHOOL_ID = '62de6aaee92fe0c34df6d645'
export const INFORMATION_CATEGORY_TITLES = ['AVOSからのお知らせ','フリーランスティーチャーシリーズ']
export const UPDATE_CATEGORY_TITLES = ['アップデート','新機能紹介']
